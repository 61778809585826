
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import MainBanner from "components/sections/MainBanner";
import styles from "pages/index.module.scss";
import { useContext, useEffect as reactUseEffect, useMemo, useState } from "react";
import { Axios, defaultCacheOption } from "api";
import { productScrapContextRef } from "context/ProductScrapContext";
import { captureException, captureMessage } from "@sentry/nextjs";
import { SITE_URL } from "common/const";
import { postScrapContextRef } from "context/PostScrapContext";
import { getLocalStorageCountryCode, isApp } from "common/utils";
import Head from "next/head";
import { useRouter } from "next/router";
import UserContext from "context/AuthContext";
import PushContext from "context/PushContext";
import { KEY_PUSH_MARKETING_AGREEMENT } from "pages/mypage/my-info";
import Layout from "components/layout/layout";
import TopBar from "components/layout/TopBar/TopBar";
import { MainPageSectionList } from "components/sections/mainpage/MainPageSectionList";
import HorizontalShortcut from "components/HorizontalShortcut";
import TallMainBanner from "components/sections/TallMainBanner";
import useTranslation from "next-translate/useTranslation";
import { setCountryCodeInCookieSSR } from "common/i18n";
import ModalContext from "context/ModalContext";
const CATEGORY_TOP_PRODUCT = "category_top_products";
const ROW_CHUNK_SIZE = 20;
const fetchMainPageInfo = async (cookie) => {
    let mainPageInfo = {};
    try {
        const res = await Axios.get(`/v1/main-page-info`, {
            headers: { Cookie: cookie || '' },
            cache: defaultCacheOption,
            params: { banner_type: 'vertical' }
        });
        if (res.status < 400) {
            mainPageInfo = res.data;
        }
        else {
            captureMessage(JSON.stringify(res.data));
        }
    }
    catch (e) {
        captureException(e);
    }
    return mainPageInfo;
};
const SHORTCUT_NAME_RANKING = 'ranking';
const SHORTCUT_NAME_HAIR = 'hair';
const SHORTCUT_NAME_MAKEUP = 'makeup';
const SHORTCUT_NAME_SKINCARE = 'skincare';
const SHORTCUT_NAME_TOOL = 'beauty-tool';
const SHORTCUT_NAME_SPECIAL_PRICE = 'special-price';
const SHORTCUT_NAME_EVENT = 'event';
const SHORTCUT_NAME_FEED = 'feed';
const SHORTCUT_NAME_BRAND = 'brand';
const SHORTCUT_NAME_SELLER_SHOP = 'seller-shop';
const SHORTCUT_NAME_RESERVATION = 'reservation';
const SHORTCUT_NAME_REWARD = 'reward';
const SHORTCUT_NAME_PROFESSIONAL_PRICE = 'pro-price';
const LOCAL_STORAGE_KEY_PUSH_MODAL_CLOSED = 'push_modal_closed';
const Index = (props) => {
    const userContext = useContext(UserContext);
    const fetchUser = userContext.fetchUser;
    const user = userContext.user;
    const { locale } = props;
    const pushContext = useContext(PushContext);
    const [isPushModalOpen, setIsPushModalOpen] = useState(false);
    const [mainPageInfo, setMainPageInfo] = useState(props.mainPageInfo || {});
    console.log('mainPageInfo', mainPageInfo);
    const router = useRouter();
    const isSSR = props.isSsr;
    const [isCsrLoading, setIsCsrLoading] = useState(true);
    const useEffect = props.useEffect || reactUseEffect;
    useEffect(async () => {
        if (!isSSR) {
            setIsCsrLoading(true);
            const mainPageInfo = await fetchMainPageInfo();
            setIsCsrLoading(false);
            setMainPageInfo(mainPageInfo);
        }
    }, []);
    useEffect(() => {
        if (!user)
            return;
        const isModalCloseDone = window.localStorage.getItem(LOCAL_STORAGE_KEY_PUSH_MODAL_CLOSED);
        if (!isModalCloseDone && isApp()) {
            pushContext.hasPushPermission((enabled) => {
                if (user) {
                    if (!user[KEY_PUSH_MARKETING_AGREEMENT] || enabled) {
                        setIsPushModalOpen(true);
                    }
                }
            });
        }
    }, [user]);
    useEffect(() => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ViewMainPage');
        fetchUser();
    }, []);
    useEffect(() => {
        if (!productScrapContextRef.current)
            return;
        if (!postScrapContextRef.current)
            return;
        if (!mainPageInfo.curations)
            return;
        for (const curation of mainPageInfo.curations) {
            if (curation.category === 'product' || curation.category === 'daily') {
                productScrapContextRef.current.addProductIds(curation.scrapped_pids);
            }
            else if (curation.category === 'post') {
                postScrapContextRef.current.addPostIds(curation.scrapped_pids);
            }
        }
    }, [mainPageInfo, productScrapContextRef.current, postScrapContextRef]);
    const newShortCuts = mainPageInfo.new_shortcuts || [];
    const onBannerClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainPageShortcutBannerClick', { isLoggedIn: mainPageInfo.is_logged_in });
    };
    const receivedCouponMoldIds = mainPageInfo.received_coupon_mold_ids || [];
    const { openModal, closeModal, registerAppModalCloseCallback } = useContext(ModalContext);
    // useEffect(() => {
    //     const mainPageModals = mainPageInfo?.main_page_modals;
    //     if (mainPageModals) {
    //         for (const modal of mainPageModals) {
    //             if (modal.coupon_molds.filter(
    //                 e => e.is_duplication_allowed || !receivedCouponMoldIds.includes(e.id)
    //             ).length === 0) continue;
    //
    //             if (!checkIfOpenAllowed(mainCouponModalKey(modal.id))) {
    //                 continue;
    //             }
    //
    //             if (modal.is_app_only && !isApp()) {
    //                 continue;
    //             }
    //
    //             openModal(() =>
    //                 <MainCouponModal
    //                     modalInfo={modal} close={closeModal}
    //                     receivedCouponMoldIds={receivedCouponMoldIds}
    //                 />
    //             );
    //             break;
    //         }
    //     }
    // }, [mainPageInfo]);
    const isPro = !!user && !!user.pro_grade;
    const isBetaTester = !!user && user.is_beta_tester;
    const { t } = useTranslation('home');
    const countryCode = getLocalStorageCountryCode() ?? 'KR';
    return (<>
            <Head>
                <link rel="canonical" href={`${SITE_URL}${router.asPath}`}/>
            </Head>
            <TallMainBanner banners={mainPageInfo.banners} isLoading={isCsrLoading}/>
            <div className={styles.pageContainer}>
                <HorizontalShortcut newShortCuts={newShortCuts} isPro={isPro}/>
                {mainPageInfo.mid_banners &&
            mainPageInfo.mid_banners.length > 0 &&
            <div className={styles.midBannerContainer}>
                        <div className={styles.midBannerWrapper}>
                            <MainBanner banners={mainPageInfo.mid_banners} isLoading={false} hidePaginator small/>
                        </div>
                    </div>}
                <MainPageSectionList />
            </div>
        </>);
};
export const _Index = Index;
const WrappedIndex = (props) => {
    const user = useContext(UserContext).user;
    return (<Layout topBar={<TopBar search isPro={!!user && user.pro_grade} notification 
        // order
        pouch/>}>
            <Index {...props}/>
        </Layout>);
};
async function getServerSideProps(context) {
    setCountryCodeInCookieSSR(context);
    const mainPageInfo = await fetchMainPageInfo(context.req.headers.cookie);
    const { locale } = context;
    return {
        props: {
            mainPageInfo: mainPageInfo,
            isSsr: true,
            locale
        }
    };
}
export default WrappedIndex;
WrappedIndex.restoreScroll = true;

    async function __Next_Translate__getServerSideProps__1933462f2d0__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1933462f2d0__ as getServerSideProps }
  