import styles from "components/HorizontalShortcut.module.scss";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import {
    brandRoute,
    eventsRoute,
    getStoreCategoryRoute,
    professionalPriceRoute,
    reservationProductsRoute,
    rewardsRoute,
    specialOffersRoute,
    storeRoute
} from "common/const";
import {HASH_PRODUCT_LIST} from "pages/store/products";
import RankingIcon from "images/icons/ranking.svg";
import MakeupShortcutIcon from "images/icons/makeup_shortcut.svg";
import HairShortcutIcon from "images/icons/hair_shortcut.svg";
import SkincareShortcutIcon from "images/icons/skincare_shortcut.svg";
import BeautyToolShortcutIcon from "images/icons/beauty_tool_shortcut.svg";
import TimeSaleIcon from "images/icons/time_sale.svg";
import EventIcon from "images/static/event.png";
import BrandsIcon from "images/icons/brands.svg";
import RewardIcon from "images/static/present_box.png";
import HemeTicketIcon from "images/static/heme_ticket_icon.png";
import * as PropTypes from "prop-types";
import Link from "next/link";
import NewBadgeIcon from "images/icons/new_badge.svg";
import {getLocalStorageCountryCode} from "common/utils";
import useTranslation from "next-translate/useTranslation";

const SHORTCUT_NAME_RANKING = '랭킹';
const SHORTCUT_NAME_HAIR = '헤어';
const SHORTCUT_NAME_MAKEUP = '메이크업';
const SHORTCUT_NAME_SKINCARE = '스킨케어';
const SHORTCUT_NAME_TOOL = '뷰티툴';
const SHORTCUT_NAME_SPECIAL_PRICE = '헤메코특가';
const SHORTCUT_NAME_EVENT = '이벤트';
const SHORTCUT_NAME_FEED = '리뷰피드';
const SHORTCUT_NAME_BRAND = '브랜드';
const SHORTCUT_NAME_SELLER_SHOP = '편집샵';
const SHORTCUT_NAME_RESERVATION = '헤메티켓';
const SHORTCUT_NAME_REWARD = '리워드';

const SHORTCUT_NAME_PROFESSIONAL_PRICE = 'PRO특가';

const Shortcut = ({link, icon, name, shortcutIdx, isNew,}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainPageShortcutClick', {name, shortcutIdx, isNew});
    };

    return (
        <div className={styles.shortcutWrapper} onClick={onClick}>
            <Link href={link}>
                <a className={styles.shortcut}>
                    <div className={styles.icon}>
                        {icon}
                        {
                            !!isNew &&
                            <div className={styles.newBadge}>
                                <NewBadgeIcon/>
                            </div>
                        }
                    </div>
                    <span className={styles.shortcutText}>{name}</span>
                </a>
            </Link>
        </div>
    )
}

Shortcut.propTypes = {
    isNew: PropTypes.any,
    link: PropTypes.string,
    icon: PropTypes.element,
    name: PropTypes.any,
    shortcutIdx: PropTypes.number
};

const shortCuts = [
    SHORTCUT_NAME_PROFESSIONAL_PRICE,
    SHORTCUT_NAME_SPECIAL_PRICE,
    SHORTCUT_NAME_EVENT,
    SHORTCUT_NAME_BRAND,
    SHORTCUT_NAME_REWARD,
    SHORTCUT_NAME_RESERVATION,
    SHORTCUT_NAME_RANKING,
    SHORTCUT_NAME_MAKEUP,
    SHORTCUT_NAME_HAIR,
    SHORTCUT_NAME_SKINCARE,
    SHORTCUT_NAME_TOOL,
]

const globalShortCuts = [
    SHORTCUT_NAME_MAKEUP,
    SHORTCUT_NAME_HAIR,
    SHORTCUT_NAME_SKINCARE,
    SHORTCUT_NAME_TOOL,
    SHORTCUT_NAME_RANKING,
    SHORTCUT_NAME_EVENT,
    SHORTCUT_NAME_BRAND,
    SHORTCUT_NAME_REWARD,
]

const getShortCutParamsByName = (name, isPro, newShortCuts, t) => {
    switch (name) {
        case SHORTCUT_NAME_PROFESSIONAL_PRICE:
            if (!isPro) {
                return null;
            }
            return {
                name: t(SHORTCUT_NAME_PROFESSIONAL_PRICE),
                link: professionalPriceRoute,
                icon: <img src={'https://d1cnx04b8cgzcv.cloudfront.net/pricetag_color.png'}
                           style={{width: 38, height: 38, marginBottom: 2}}
                           alt={t(SHORTCUT_NAME_PROFESSIONAL_PRICE)}
                />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_PROFESSIONAL_PRICE),
            };
        case SHORTCUT_NAME_SPECIAL_PRICE:
            return {
                name: t(SHORTCUT_NAME_SPECIAL_PRICE),
                link: specialOffersRoute,
                icon: <TimeSaleIcon viewBox="0 0 28 28"/>,
                isNew: newShortCuts.includes(SHORTCUT_NAME_SPECIAL_PRICE),
                shortcutIdx: 5,
            };
        case SHORTCUT_NAME_EVENT:
            return {
                name: t(SHORTCUT_NAME_EVENT),
                link: eventsRoute,
                icon: <img src={EventIcon.src} width={40} height={40} alt={t(SHORTCUT_NAME_EVENT)}/>,
                isNew: newShortCuts.includes(SHORTCUT_NAME_EVENT),
                shortcutIdx: 6,
            };
        case SHORTCUT_NAME_BRAND:
            return {
                name: t(SHORTCUT_NAME_BRAND),
                link: brandRoute,
                icon: <BrandsIcon viewBox="0 0 40 40" />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_BRAND),
                shortcutIdx: 7,
            };
        case SHORTCUT_NAME_REWARD:
            return {
                name: t(SHORTCUT_NAME_REWARD),
                link: rewardsRoute,
                icon: <img src={RewardIcon.src} width={48} height={48} alt={t(SHORTCUT_NAME_REWARD)}/>,
                isNew: newShortCuts.includes(SHORTCUT_NAME_REWARD),
                shortcutIdx: isPro ? 9 : 8,
            };
        case SHORTCUT_NAME_RESERVATION:
            if(isPro) {
                return null;
            }
            return {
                name: t(SHORTCUT_NAME_RESERVATION),
                link: reservationProductsRoute,
                icon: <img src={HemeTicketIcon.src} width={54} height={54} alt={t(SHORTCUT_NAME_RESERVATION)}/>,
                isNew: newShortCuts.includes(SHORTCUT_NAME_RESERVATION),
                shortcutIdx: 9,
            };
        case SHORTCUT_NAME_RANKING:
            return {
                name: t(SHORTCUT_NAME_RANKING),
                link: `${storeRoute}?order=sell_count#${HASH_PRODUCT_LIST}`,
                icon: <RankingIcon viewBox="0 0 28 28"/>,
                isNew: newShortCuts.includes(SHORTCUT_NAME_RANKING),
                shortcutIdx: 0,
            };
        case SHORTCUT_NAME_MAKEUP:
            return {
                name: t(SHORTCUT_NAME_MAKEUP),
                link: getStoreCategoryRoute('메이크업'),
                icon: <MakeupShortcutIcon viewBox="0 0 28 28" />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_MAKEUP),
                shortcutIdx: 1,
            };
        case SHORTCUT_NAME_HAIR:
            return {
                name: t(SHORTCUT_NAME_HAIR),
                link: getStoreCategoryRoute('헤어'),
                icon: <HairShortcutIcon viewBox="0 0 28 28" />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_HAIR),
                shortcutIdx: 2,
            };
        case SHORTCUT_NAME_SKINCARE:
            return {
                name: t(SHORTCUT_NAME_SKINCARE),
                link: getStoreCategoryRoute('스킨케어'),
                icon: <SkincareShortcutIcon viewBox="0 0 28 28" />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_SKINCARE),
                shortcutIdx: 3,
            };
        case SHORTCUT_NAME_TOOL:
            return {
                name: t(SHORTCUT_NAME_TOOL),
                link: getStoreCategoryRoute('메이크업_툴/디바이스'),
                icon: <BeautyToolShortcutIcon viewBox="0 0 28 28" />,
                isNew: newShortCuts.includes(SHORTCUT_NAME_TOOL),
                shortcutIdx: 4,
            };
        default:
            return null;
    }
}

const getShortCut = (name, isPro, newShortCuts, t) => {
    const params = getShortCutParamsByName(name, isPro, newShortCuts, t);

    if (!params) {
        return <div key={`icon-${name}`}/>;
    }

    return <Shortcut key={`icon-${name}`} {...params}/>
}

const HorizontalShortcut = (props) => {
    const {
        width, onShowMoreClick, isColorBackground, newShortCuts, isPro
    } = props;

    const {t} = useTranslation('components-shortcut')
    const countryCode = getLocalStorageCountryCode() ?? 'KR';

    const supportedShortCuts = countryCode === 'KR' ? shortCuts : globalShortCuts;

    return (
        // visibility scrollbar on windows
        <OverlayScrollbarsComponent
            defer
            options={{
                scrollbars: {
                    autoHide: 'leave',
                    autoHideDelay: 500,
                    // theme: 'os-theme-light',
                },
            }}
        >
            <div className={styles.shortcutContainer} {...{'body-scroll-lock-ignore': "true"}}>
                <div className={styles.shortcutRow}>
                    {
                        supportedShortCuts.map((name, idx) => {
                            return getShortCut(name, isPro, newShortCuts, t);
                        })
                    }
                </div>
            </div>
        </OverlayScrollbarsComponent>
    )
};

export default HorizontalShortcut;
